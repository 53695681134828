<template>
  <div>
    <div class="area-div flex space-between">
      <div>接受新商机通知</div>
      <div>
        <el-switch v-model="info.notify" :active-value="1" :inactive-value="0" active-color="#F98231">
        </el-switch>
        <span class="notice margin-small-left">{{info.notify==1?'已开启':'已关闭'}}</span>
      </div>
    </div>
    <div class="area-div" v-if="info.notify==1">
      <div class="flex space-between">
        <div>商机推送时间段</div>
        <div>
          <el-switch v-model="info.timerange" :active-value="1" :inactive-value="2" active-color="#F98231">
          </el-switch>
          <span class="notice margin-small-left">{{info.timerange==1?'全天':'时间段'}}</span>
        </div>
      </div>
      <div class="flex space-between margin-top" v-if="info.timerange==2">
        <div>开始时间</div>
        <div>
          <el-time-picker v-model="info.starttime" placeholder="选择开始时间" value-format="yyyy-MM-dd HH:mm:ss" size="mini" style="width:150px" :editable="false">
          </el-time-picker>
        </div>
      </div>
      <div class="flex space-between margin-top" v-show="info.timerange==2">
        <div>结束时间</div>
        <div>
          <el-time-picker v-model="info.endtime" placeholder="选择结束时间" value-format="yyyy-MM-dd HH:mm:ss" size="mini" style="width:150px" :editable="false">
          </el-time-picker>
        </div>
      </div>
    </div>
    <div class="area-div" v-if="info.notify==1">
      <div class="text-left">接收以下地区的商机（可多选）</div>
      <div class="area-list">
        <div class="area-item" v-for="(item,index) in areaList" :key="index" :class="info.area.indexOf(item.code)!=-1?'area-select':''" @click="areaChange(item.code)">{{ item.name }}{{ item.select }}</div>
      </div>
      <el-button @click="submit" class="margin-top" style="width:100%;border-radius:30px;background-color: #F98231;color:white">提交保存</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      areaList: [],
      info: {
        notify: 1,
        timerange: 1,
        starttime: "5:00",
        endtime: 0,
        area: [],
      },
      isLogin: sessionStorage.getItem("isLogin"),
    };
  },
  watch: {
    isLogin(newValue) {
      if (newValue == 1) {
        this.getInfo();
      }
    },
  },
  methods: {
    getInfo() {
      this.$axios({
        url: "/setting/getNotifySetting",
        showInfo: 0,
      }).then((res) => {
        for (let item in this.info) {
          this.info[item] = res.info[item];
        }
        console.log(this.info);
      });
    },
    areaChange(code) {
      let index = this.info.area.indexOf(code);
      if (index === -1) {
        this.info.area.push(code);
      } else {
        this.info.area.splice(index, 1);
      }
      console.log(this.info.area);
      // this.areaList[index].select = !this.areaList[index].select
      // console.log(this.areaList)
    },
    submit() {
      this.$axios({
        url:'/setting/setNotifySetting',
        data:{
          info:this.info
        }
      }).then(res=>{

      })
    },
  },
  created() {
    this.getCommonInfo(["areaList"]);
    if (this.isLogin == 1) {
      this.getInfo();
    } else {
      window.addEventListener("setItem", () => {
        this.isLogin = sessionStorage.getItem("isLogin");
      });
    }
  },
};
</script>