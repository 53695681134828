<template>
  <div class="shangji-intro" v-if="detail != ''">
    <div class="title">{{ info.title }}</div>
    <div class="time">发布时间：{{ info.create_time }}</div>
    <el-divider></el-divider>
    <div>联系人：<span style="font-weight: bold;">{{ info.contact }} ({{ info.area_text }})</span></div>
    <!-- <div>联系电话：<span style="color:red;font-weight: bold;">{{ info.tel }}</span><a style="margin-left:10px" :href="'tel:'+info.tel">一键拨号</a></div> -->
    <div>联系电话：<a style="color:red;font-weight: bold;" :href="'tel:'+info.tel">{{ info.tel }}</a></div>
    <div class="margin-top margin-bottom flex space-between align-center">
      <div style="flex-grow: 1;">
        <el-progress :percentage="(info.crntnum/info.num)*100" :stroke-width="20" color="#f00" :format="format"></el-progress>
      </div>
    </div>
    <div class="flex align-center">
      商机状态：
      <el-select v-model="shangji_status" size="mini">
        <el-option label="未备注" :value="0"></el-option>
        <el-option v-for="(item,index) in shangjiStatusList" :key="index" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-button @click="setShangjiStatus" size="mini" class="margin-left">提交</el-button>
    </div>
    <el-divider></el-divider>
    <div class="detail ql-editor" v-html="detail"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: "",
      info: {},
      isLogin: sessionStorage.getItem("isLogin"),
      shangjiStatusList:[],
      shangji_status:0
    };
  },
  watch: {
    isLogin(newValue) {
      if (newValue == 1) {
        this.getInfo();
      }
    },
  },
  methods: {
    format() {
      return this.info.crntnum + "/" + this.info.num;
    },
    getInfo() {
      this.$axios({
        url: "/shangji/getBuyedInfo",
        data: {
          shangjiId: this.$route.params.id,
        },
        showInfo: 0,
      }).then((res) => {
        this.detail = res.detail;
        this.info = res.info;
        this.shangji_status = res.shangji_status
      });
    },
    setShangjiStatus(){
      this.$axios({
        url: "/shangji/setShangjiStatus",
        data: {
          id: this.$route.params.id,
          status:this.shangji_status
        },
      }).then((res) => {
        
      });
    }
  },
  created() {
    if (!this.$route.params.id) {
      this.goPath("/");
    }
    this.getCommonInfo(['shangjiStatusList'])
    if (this.isLogin == 1) {
      this.getInfo();
    } else {
      window.addEventListener("setItem", () => {
        this.isLogin = sessionStorage.getItem("isLogin");
      });
    }
  },
};
</script>