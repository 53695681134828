<template>
  <div>
    <div class="margin-top"><h2>金牌会员服务</h2></div>
    <el-divider></el-divider>
    <div class="margin">
      <el-table :data="list" row-key="id" border>
        <el-table-column label="等级" width="60" align="center">
          <template slot-scope="scope">
            <span style="color:#FE771B">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="服务内容">
          <template slot-scope="scope">
            优先<span style="color:#FE771B">{{ scope.row.minutes }}分钟</span>收到提醒
          </template>
        </el-table-column>
        <el-table-column label="年费价格" align="center">
          <template slot-scope="scope">
            <div>{{scope.row.price}}元/年</div>
            <el-button @click="buyMember(scope.row.id)" size="mini" type="success">自助开通{{ scope.row.name }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="bindDiv" :show-close="false" :title="title" :close-on-click-modal="false" width="80%">
      <div class="flex justify-center">
        <el-button @click="getUserInfo" type="success" style="width:100%">授权</el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="bindDiv = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      isLogin: sessionStorage.getItem("isLogin"),
      bindDiv:false,
      title:''
    };
  },
  watch: {
    isLogin(newValue) {
      if (newValue == 1) {
        this.getInfo();
      }
    },
  },
  methods: {
    getInfo() {
      this.$axios({
        url: "/setting/getLevelList",
        showInfo:0
      }).then((res) => {
        this.list = res.list;
      });
    },
    getUrlKey(name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    getUserInfo() {
      this.$axios({
        url: "/login/getAppid",
        showInfo: 0,
      }).then((res) => {
        let redirect_uri =
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port ? ":" + window.location.port : "") +
          window.location.pathname;
        let url =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          res.appid +
          "&redirect_uri=" +
          redirect_uri +
          "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
        window.location.replace(url);
      });
    },
    buyMember(id) {
      this.$axios({
        url: "/setting/buyMember",
        data: {
            levelId:id
        },
      }).then((res) => {
        if (res.status == 1) {
          this.onBridgeReady(res);
        }else if(res.status == 2){
          this.title = res.info
          this.bindDiv = true;
        }
      });
    },

    onBridgeReady(data) {
      let _this = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: data.appId, //公众号名称，由商户传入
          timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: data.nonceStr, //随机串
          package: data.package,
          signType: data.signType, //微信签名方式：
          paySign: data.sign, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            _this.goPath("/personal");
          } else {
            _this.$message({
              type: "error",
              message: "支付失败",
            });
          }
        }
      );
    },
  },
  created() {
    if (this.isLogin == 1) {
      let code = this.getUrlKey("code");
      if (code) {
        this.$axios({
          url: "/login/getUserInfo",
          data: {
            code: code,
          },
          showInfo: 0,
        }).then((res) => {
          if (res.status == 1) {
            this.getInfo();
            this.$forceUpdate();
          }
        });
      } else {
        this.getInfo();
      }
    } else {
      window.addEventListener("setItem", () => {
        this.isLogin = sessionStorage.getItem("isLogin");
      });
    }
  },
};
</script>