<template>
  <div class="shangji-div">
    <el-empty description="暂无记录" v-if="list.length==0"></el-empty>
    <div class="shangji-lists" v-else>
      <div class="shangji-list">
        <div v-for="(item,index) in list" :key="index" class="shangji-item">
          <div>
            <p>订单号：{{ item.ordersn }}</p>
            <p>商机名称：{{ item.title }}</p>
            <p>购买条数：{{ item.num }}</p>
            <p>总金额：{{ item.total }}元</p>
            <p>购买时间：{{ item.pay_time }}</p>
          </div>
          <div class="text-center"><el-button class="show-more-btn" size="mini" @click="goPath('/shangjidetail/'+item.id)">查看详情</el-button></div>
        </div>
      </div>
      <el-pagination background :current-page="page" :page-size="10" @current-change="handleCurrentChange" layout="prev, pager, next, total" :total="total"></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLogin: sessionStorage.getItem("isLogin"),
      list: [],
      page: 1,
      total: 0,
    };
  },
  methods: {
    getList() {
      this.$axios({
        url: "/shangji/getOrderList",
        data: {
          page: this.page,
        },
        showInfo: 0,
      }).then((res) => {
        if (res.status == 1) {
          this.list = res.list.data;
          this.total = res.list.total;
        }
      });
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getList();
    },
  },

  watch: {
    isLogin(newValue) {
      if (newValue == 1) {
        this.getList();
      }
    },
  },
  created() {
    console.log(this.isLogin);
    if (this.isLogin == 1) {
      this.getList();
    } else {
      window.addEventListener("setItem", () => {
        this.isLogin = sessionStorage.getItem("isLogin");
      });
    }
  },
};
</script>