<template>
  <div class="shangji-div">
    <div>
      <el-row :gutter="10">
        <el-input placeholder="请输入搜索关键词" class="margin-bottom" v-model="keyword" size="mini"></el-input>
      </el-row>
      <el-row :gutter="10" class="margin-bottom">
        <el-col :span="10">
          <el-date-picker v-model="selectDate" type="date" placeholder="选择日期" size="mini" style="width:100%">
          </el-date-picker>
        </el-col>
        <el-col :span="10">
          <el-select v-model="shangjistatus" size="mini" style="width:100%">
            <el-option v-for="(item,index) in shangjiStatusList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button size="mini" @click="searchShangji" style="width:100%"><i class="el-icon-search"></i></el-button>
        </el-col>
      </el-row>
    </div>
    <el-empty description="暂无记录" v-if="list.length==0" style="flex-grow:1"></el-empty>
    <div class="shangji-lists" v-else style="flex-grow:1">
      <div class="shangji-list">
        <div v-for="(item,index) in list" :key="index" class="shangji-item">
          <div>
            <p>商机名称：{{ item.title }}</p>
            <p>联系方式：{{ item.tel }}</p>
            <p>购买时间：{{ item.time }}</p>
            <p>备注状态：{{ item.status }}</p>
          </div>
          <div class="text-center"><el-button class="show-more-btn" size="mini" @click="goPath('/detail/'+item.id)">查看详情</el-button></div>
        </div>
      </div>
      <el-pagination background :current-page="page" :page-size="10" @current-change="handleCurrentChange" layout="prev, pager, next, total" :total="total"></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      keyword: "",
      page: 1,
      total: 0,
      selectDate: "",
      shangjistatus: "",
      shangjiStatusList: [],
      isLogin: sessionStorage.getItem("isLogin"),
    };
  },
  watch: {
    isLogin(newValue) {
      if (newValue == 1) {
        this.getList();
      }
    },
  },
  methods: {
    getList() {
      this.$axios({
        url: "/shangji/getBuyList",
        data: {
          keyword: this.keyword,
          date: this.selectDate,
          shangjistatus: this.shangjistatus,
          page: this.page,
        },
        showInfo: 0,
      }).then((res) => {
        this.list = res.list.data;
        this.total = res.list.total;
      });
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getList();
    },
    searchShangji() {
      this.page = 1;
      this.getList();
    },
  },
  created() {
    this.getCommonInfo(["shangjiStatusList"]);
    if (this.isLogin == 1) {
      this.getList();
    } else {
      window.addEventListener("setItem", () => {
        this.isLogin = sessionStorage.getItem("isLogin");
      });
    }
  },
};
</script>