<template>
  <div class="shangji-div" style="background-image: url('/static/images/index_bg.jpg');background-repeat: repeat-x;">
    <div class="flex space-between align-center margin-bottom">
      <span style="white-space: nowrap;font-size:30px;color:white;font-style: italic;">{{ webname }}</span>
      <div style="display: flex;">
        <el-input placeholder="请输入搜索关键词" class="margin-left home-search-btn" v-model="keyword"></el-input>
        <div class="position-center" style="background-color: #FFFFFF;width:50px;border-top-right-radius:50px;border-bottom-right-radius:50px;" @click="searchShangji"><img style="width:30px;height:30px" src="/static/images/search.png" alt=""></div>
      </div>
    </div>
    <el-empty description="暂无记录" v-if="list.length==0" style="flex-grow:1"></el-empty>
    <div class="shangji-lists" v-else style="flex-grow:1">
      <div class="shangji-list">
        <div v-for="(item,index) in list" :key="index" class="shangji-item">
          <div class="title">{{ item.title }}</div>
          <div>
            <p>地区：{{ item.area_text }}</p>
            <p>联系方式：{{ item.tel }}</p>
            <p>发布时间：{{ item.create_time }}</p>
            <!-- <p>剩余数量：{{ item.num-item.crntnum }}</p> -->
          </div>
          <div class="flex space-between align-center">
            <div style="flex-grow: 1;border-right:1px solid #F1F1F1;padding-right:10px" class="margin-right">
              <p class="text-center"><span style="font-size: 20px;color:red">{{ item.num-item.crntnum }}</span>次可购买次数</p>
              <div class="flex space-between align-center">
                <el-progress :percentage="(item.crntnum/item.num)*100" :show-text="false" :stroke-width="10" color="#f00" :format="format" style="flex-grow: 1;"></el-progress>
                <span class="margin-left">{{ item.crntnum }}/{{ item.num }}</span>
              </div>
            </div>
            <div>
              <el-button class="show-more-btn" size="mini" @click="goPath('/shangjidetail/'+item.id)">查看详情</el-button>
            </div>
          </div>
        </div>
      </div>
      <el-pagination background :current-page="page" :page-size="10" @current-change="handleCurrentChange" layout="prev, pager, next, total" :total="total"></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      keyword: "",
      page: 1,
      total: 0,
      webname: "",
      isLogin: sessionStorage.getItem("isLogin"),
    };
  },
  computed: {},
  watch: {
    isLogin(newValue) {
      if (newValue == 1) {
        this.getList();
      }
    },
  },
  methods: {
    getList() {
      this.$axios({
        url: "/shangji/getList",
        data: {
          keyword: this.keyword,
          page: this.page,
        },
        showInfo: 0,
      }).then((res) => {
        this.list = res.list.data;
        this.total = res.list.total;
      });
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getList();
    },
    searchShangji() {
      this.page = 1;
      this.getList();
    },
    format(percent,item) {
      return '';
    },
  },
  created() {
    this.getCommonInfo(["webname"]);
    if (this.isLogin == 1) {
      this.getList();
    } else {
      window.addEventListener("setItem", () => {
        this.isLogin = sessionStorage.getItem("isLogin");
      });
    }
  },
};
</script>
<style>
.home-search-btn > .el-input__inner {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border: none;
}
</style>