<template>
  <div>
    <div class="area-div">
      <div class="text-left" style="line-height: 25px;">
        <p style="color:red">*您关注的产品是？</p>
        <span>
          请输入您关注的产品关键词（可填写多个，多个用换行区分），系统将<span style="color:red">精准匹配</span>相关的商机信息，每天<span style="color:red">第一时间</span>推送给您
        </span>
      </div>
      <div class="margin-top">
        <el-input type="textarea" rows="15" v-model="keyword" maxlength="80" show-word-limit></el-input>
      </div>
      <el-button @click="submit" class="margin-top" style="width:100%;border-radius:30px;background-color: #F98231;color:white">提交保存</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      keyword: "",
      isLogin: sessionStorage.getItem("isLogin"),
    };
  },
  watch: {
    isLogin(newValue) {
      if (newValue == 1) {
        this.getInfo();
      }
    },
  },
  methods: {
    getInfo() {
      this.$axios({
        url: "/setting/getKeyword",
        showInfo: 0,
      }).then((res) => {
        this.keyword = res.keyword;
      });
    },
    submit() {
      this.$axios({
        url: "/setting/setKeyword",
        data: {
          keyword: this.keyword,
        },
      }).then((res) => {});
    },
  },
  created() {
    if (this.isLogin == 1) {
      this.getInfo();
    } else {
      window.addEventListener("setItem", () => {
        this.isLogin = sessionStorage.getItem("isLogin");
      });
    }
  },
};
</script>