<template>
  <div>
    <div style="background-color: #fff;">
      <div class="userinfo-div">
        <div>
          <span style="font-size:16px;font-weight: bold;">{{ info.nickname || '微信用户' }}</span><span class="noticy"> / 注册会员</span>
          <p v-if="!info.avator" style="color:#909399;font-size:12px;line-height: 25px;" @click="getUserInfo">授权获取微信信息</p>
        </div>
        <div class="user-avator">
          <img :src="info.avator || '/static/images/default_user.png'" alt="">
          <!-- <div class="notice-div position-center">
            <img src="/static/images/edit.png" alt="" @click="getUserInfo">
          </div> -->
        </div>
      </div>
      <div class="flex flex-column text-left margin border">
        <div class="margin-bottom">账号余额：<span style="color:#FE771B">{{ info.balance }}元</span></div>
        <div>会员等级：<span style="color:#FE771B">{{ info.level }}</span></div>
      </div>
      <div class="text-left margin border">
        <div class="margin-bottom">我要充值<span style="color:#FE771B">(充值金额可自定义)</span></div>
        <div class="flex align-center">
          <div style="width:100px">充值金额：</div>
          <div><el-input v-model="amount" class="no-border-input" size="mini" style="font-size: 20px;"></el-input></div>
          <el-button @click="chongzhi()" type="success" size="mini" style="width:100px">充值</el-button>
        </div>
      </div>
      <div class="youhui-lists text-left border">
        <div class="margin-bottom">快捷充值优惠</div>
        <div class="youhui-list">
          <div v-for="(item,index) in chongzhiList" :key="index" @click="chongzhi(item.id)" class="youhui-item">
            <div class="youhui-detail">
              <div style="color:#5AA746;font-weight: bold;font-size: 20px;">{{ item.amount }}</div>
              <div style="color:#5AA746;font-weight: bold;font-size: 12px;">送{{ item.discount }}%</div>
              <div style="color:#AEB0AF;font-size: 10px;">实得{{ item.amount*(1+item.discount/100) }}元</div>
            </div>
          </div>
        </div>
      </div>
      <div class="padding-small-left padding-small-right">
        <el-image mode="widthFix" style="width:100%" src="/static/images/tu.jpg" @click="goPath('/personal/member')"></el-image>
      </div>
      <div class="item-list">
        <!-- <div class="item" @click="goPath('/personal/notify')">
          <div class="flex align-center">
            <img src="/static/images/notify.png" alt="">
            <span class="margin-small-left">通知设置</span>
          </div>
          <div>
            <img src="/static/images/right.png" alt="">
          </div>
        </div> -->
        <div class="item" @click="goPath('/mine')">
          <div class="flex align-center">
            <img src="/static/images/shangjilist.png" alt="">
            <span class="margin-small-left">我的商机</span>
          </div>
          <div>
            <img src="/static/images/right.png" alt="">
          </div>
        </div>
        <div class="item" @click="goPath('/personal/record')">
          <div class="flex align-center">
            <img src="/static/images/record.png" alt="">
            <span class="margin-small-left">消费记录</span>
          </div>
          <div>
            <img src="/static/images/right.png" alt="">
          </div>
        </div>
        <!-- <div class="item" @click="goPath('/personal/member')">
          <div class="flex align-center">
            <img src="/static/images/diy.png" alt="">
            <span class="margin-small-left">金牌会员服务</span>
          </div>
          <div>
            <img src="/static/images/right.png" alt="">
          </div>
        </div> -->
      </div>
      <el-dialog :visible.sync="bindDiv" :show-close="false" :title="title" :close-on-click-modal="false" width="80%">
        <div class="flex justify-center">
          <el-button @click="getUserInfo" type="success" style="width:100%">授权</el-button>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="bindDiv = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>

  </div>
</template>
  <script>
export default {
  data() {
    return {
      info: {},
      isLogin: sessionStorage.getItem("isLogin"),
      picHost: "",
      chongzhiList: [],
      amount: 10000,
      bindDiv: false,
      title: "",
    };
  },
  watch: {
    isLogin(newValue) {
      if (newValue == 1) {
        this.getInfo();
      }
    },
  },
  methods: {
    getUrlKey(name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    getInfo() {
      this.$axios({
        url: "/setting/getPersonalInfo",
        showInfo: 0,
      }).then((res) => {
        if (res.status == 1) {
          this.info = res.detail;
        }
      });
    },
    getChongzhiList() {
      this.$axios({
        url: "/setting/getYouhui",
        showInfo: 0,
      }).then((res) => {
        if (res.status == 1) {
          this.chongzhiList = res.list;
        }
      });
    },
    getUserInfo() {
      this.$axios({
        url: "/login/getAppid",
        showInfo: 0,
      }).then((res) => {
        let redirect_uri =
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port ? ":" + window.location.port : "") +
          window.location.pathname;
        let url =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          res.appid +
          "&redirect_uri=" +
          redirect_uri +
          "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
        window.location.replace(url);
      });
    },
    chongzhi(id) {
      let param = arguments[0]
        ? {
            youhuiId: id,
          }
        : {
            amount: this.amount,
          };
      this.$axios({
        url: "/setting/chongzhi",
        data: param,
      }).then((res) => {
        if (res.status == 1) {
          this.onBridgeReady(res);
        } else if (res.status == 2) {
          this.title = res.info;
          this.bindDiv = true;
        }
      });
    },
    onBridgeReady(data) {
      let _this = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: data.appId, //公众号名称，由商户传入
          timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: data.nonceStr, //随机串
          package: data.package,
          signType: data.signType, //微信签名方式：
          paySign: data.sign, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            _this.getInfo();
          } else {
            _this.$message({
              type: "error",
              message: "支付失败",
            });
          }
        }
      );
    },
  },
  created() {
    if (this.isLogin == 1) {
      let code = this.getUrlKey("code");
      if (code) {
        this.$axios({
          url: "/login/getUserInfo",
          data: {
            code: code,
          },
          showInfo: 0,
        }).then((res) => {
          if (res.status == 1) {
            this.getCommonInfo(["picHost"]);
            this.info.avator = res.avator;
            this.info.nickname = res.nickname;
            this.getInfo();
            this.getChongzhiList();
            this.$forceUpdate();
          }
        });
      } else {
        this.getCommonInfo(["picHost"]);
        this.getInfo();
        this.getChongzhiList();
      }
    } else {
      window.addEventListener("setItem", () => {
        this.isLogin = sessionStorage.getItem("isLogin");
        this.getCommonInfo(["picHost"]);
        this.getChongzhiList();
      });
    }
  },
};
</script>