<template>
  <div class="shangji-intro" v-if="detail != ''">
    <div class="title">{{ info.title }}</div>
    <div class="time">发布时间：{{ info.create_time }}</div>
    <el-divider></el-divider>
    <div>商机地区：{{ info.area_text }}</div>
    <div>联系方式：{{info.contact}} / <span style="color:red;">{{ info.tel }}</span><span v-if="buyed==0" class="notice margin-left">购买后可见联系方式</span></div>
    <div>商机价格：￥{{ Number(info.price) }}元/条</div>
    <div>买断价格：￥{{Number(info.allPrice)}}元</div>
    <div>商机详情：</div>
    <div class="detail ql-editor" style="white-space: pre-wrap" v-html="detail"></div>
    <div class="margin-top margin-bottom flex space-between align-center">
      <div style="flex-grow: 1;">
        <el-progress :percentage="(info.crntnum/info.num)*100" :stroke-width="20" color="#f00" :format="format"></el-progress>
      </div>
      <div class="notice margin-left">
        可购买{{info.num-info.crntnum}}次
      </div>
    </div>
    <!-- <div class="time">剩余{{ info.num-info.crntnum }}次购买机会<span v-if="buyed==0">，购买后可见联系方式</span></div> -->
    <div class="flex space-between margin-top align-center">
      <el-button @click="showNotice(1)" :style="'width:calc(100% / '+ btnNumber+' - 5px)'" type="warning">买断剩余名额</el-button>
      <el-button @click="showNotice(0)" :style="'width:calc(100% / '+ btnNumber+' - 5px)'" type="danger">立即购买</el-button>
      <el-button @click="getShangji()" v-if="isFree" :style="'width:calc(100% / '+ btnNumber+' - 5px)'" type="success">免费领取</el-button>
    </div>
    <div class="margin-top" v-if="operation != ''">
        <el-image mode="widthFix" style="width:100%" :src="$pic(operation.thumb)" @click="goPath('/personal/pay/'+operation.id)"></el-image>
      </div>
    <!-- <el-divider></el-divider> -->
    <div class="more-title" v-if="list.length>0">
      <span>最新可接单询盘</span>
      (关注 {{webname}} 网）
    </div>
    <div class="shangji-lists">
      <div class="shangji-list">
        <div v-for="(item,index) in list" :key="index" class="shangji-item" @click="showShangji(item.id)">
          <div class="title">{{ item.title }}</div>
          <div class="flex space-between margin-small-bottom">
            <div>{{ item.area_text }}</div>
            <div>{{ item.create_time }}</div>
          </div>
          <div class="flex space-between align-center">
            <el-progress :percentage="(item.crntnum/item.num)*100" :show-text="false" :stroke-width="10" color="#f00" :format="format" style="flex-grow: 1;"></el-progress>
            <span class="margin-left">{{ item.crntnum }}/{{ item.num }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="noticeDiv" :show-close="false" :close-on-click-modal="false" width="80%">
      <div class="flex flex-column align-center">
        <div class="pay-btn" @click="buyShangji(1)">
          <img src="/static/images/wxpay.png" style="height:34px" alt="">
          <span class="margin-left" style="font-size:27px">微信支付</span>
        </div>
        <div class="pay-btn" @click="buyShangji(2)">
          <img src="/static/images/money.png" style="height:34px" alt="">
          <span class="margin-left" style="font-size:27px">余额支付</span>
        </div>
      </div>
      <!-- <div class="flex space-between">
        <el-button @click="buyShangji(1)" style="width:49%" type="success">微信支付</el-button>
        <el-button style="width:49%" type="success">余额支付</el-button>
      </div> -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="noticeDiv = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="bindDiv" :show-close="false" :title="title" :close-on-click-modal="false" width="80%">
      <div class="flex justify-center">
        <el-button @click="getUserInfo" type="success" style="width:100%">授权并免费领取商机</el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="bindDiv = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="qrcodeDiv" :show-close="false" :title="title" :close-on-click-modal="false" width="80%">
      <div class="flex justify-center">
        <img :src="$pic(qrcodeUrl)" alt="" style="width:80%">
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="qrcodeDiv = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: "",
      info: {},
      buyed: 0,
      isLogin: sessionStorage.getItem("isLogin"),
      list: [],
      webname: "",
      id: "",
      isFree: false,
      all: 0,
      noticeDiv: false,
      bindDiv: false,
      qrcodeDiv: false,
      qrcodeUrl: "",
      title: "",
      operation: '',
    };
  },
  watch: {
    isLogin(newValue) {
      if (newValue == 1) {
        this.getInfo();
      }
    },
  },
  computed: {
    btnNumber() {
      return this.isFree ? 3 : 2;
    },
  },
  methods: {
    format() {
      return this.info.crntnum + "/" + this.info.num;
    },
    showNotice(all) {
      this.all = all;
      this.noticeDiv = true;
    },
    getShangji() {
      this.$confirm("确定要免费领取本商机吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: "/shangji/getShangji",
          data: {
            shangjiId: this.info.id,
          },
        }).then((res) => {
          switch (res.status) {
            case 1:
              this.goPath("/detail/" + this.info.id);
              break;
            case 2:
              this.title = res.info;
              this.bindDiv = true;
              break;
            case 3:
              this.$axios({
                url: "/setting/getQrcode",
                showInfo: 0,
                showLoading: 0,
              }).then((rest) => {
                if (rest.status == 1) {
                  this.title = res.info;
                  this.qrcodeUrl = rest.url;
                  this.qrcodeDiv = true;
                }
              });
              break;
          }
          // if (res.status == 1) {
          //   this.goPath("/detail/" + this.info.id);
          // } else if (res.status == 2) {
          //   this.$alert(res.info, {
          //     confirmButtonText: "确定",
          //     callback: () => {
          //       this.bindDiv = true;
          //     },
          //   });
          // }
        });
      });
    },
    getUserInfo() {
      this.$axios({
        url: "/login/getAppid",
        showInfo: 0,
      }).then((res) => {
        let redirect_uri =
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port ? ":" + window.location.port : "") +
          window.location.pathname;
        let url =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          res.appid +
          "&redirect_uri=" +
          redirect_uri +
          "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
        window.location.replace(url);
      });
    },
    buyShangji(type) {
      let str = this.all == 1 ? "确定要购买剩余条数吗？" : "确定要购买吗？";
      this.$confirm(str, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.noticeDiv = false;
        this.$axios({
          url: "/shangji/buyShangjiV3",
          data: {
            shangjiId: this.info.id,
            all: this.all,
            type: type,
          },
        }).then((res) => {
          if (res.status == 1) {
            if (res.orderid) {
              this.onBridgeReady(res);
            } else {
              this.goPath("/detail/" + this.info.id);
            }
          }
        });
      });
    },
    onBridgeReady(data) {
      let _this = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: data.appId, //公众号名称，由商户传入
          timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: data.nonceStr, //随机串
          package: data.package,
          signType: data.signType, //微信签名方式：
          paySign: data.sign, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            _this.goPath("/detail/" + _this.info.id);
          } else {
            _this.$message({
              type: "error",
              message: "支付失败",
            });
            _this
              .$axios({
                url: "/shangji/cancleOrder",
                data: {
                  orderId: data.orderid,
                },
                showInfo: 0,
              })
              .then((res) => {});
          }
        }
      );
    },
    getInfo() {
      this.$axios({
        url: "/shangji/getInfo",
        data: {
          shangjiId: this.$route.params.id,
        },
        showInfo: 0,
      }).then((res) => {
        this.detail = res.detail;
        this.info = res.info;
        this.isFree = res.isFree;
        this.buyed = res.buyed;
        this.list = res.list;
        this.webname = res.webname;
        this.operation = res.operation;
      });
    },
    format(percentage) {
      return this.info.crntnum + "/" + this.info.num;
    },
    showShangji(id) {
      this.goPath("/shangjidetail/" + id);
      this.$forceUpdate();
    },
    getUrlKey(name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
  },
  created() {
    if (!this.$route.params.id) {
      this.goPath("/");
    }
    this.id = this.$route.params.id;
    if (this.isLogin == 1) {
      let code = this.getUrlKey("code");
      if (code) {
        this.$axios({
          url: "/login/getUserInfo",
          data: {
            code: code,
          },
          showInfo: 0,
        }).then((res) => {
          if (res.status == 1) {
            this.getInfo();
            this.getShangji();
            this.$forceUpdate();
          }
        });
      } else {
        this.getInfo();
      }
    } else {
      window.addEventListener("setItem", () => {
        this.isLogin = sessionStorage.getItem("isLogin");
      });
    }
  },
};
</script>